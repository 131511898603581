<template>
  <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-10">
    <span class="flix-html-small">{{
      $t("time.advance.description").split("{date}")[0]
    }}</span>
    <inputSelect
      :key="data"
      :settings="{ value: data, default: defaultValue, values: values }"
      :callback="setSave"
    />
    <span class="flix-html-small">{{
      $t("time.advance.description").split("{date}")[1]
    }}</span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      defaultValue: "2 months",
      data: false,
      values: {
        "2 days": "2 " + this.$tc("dates.name.days", 2),
        "7 days": "7 " + this.$tc("dates.name.days", 7),
        "2 weeks": "14 " + this.$tc("dates.name.days", 14),
        "1 months": "1 " + this.$tc("dates.name.months", 1),
        "2 months": "2 " + this.$tc("dates.name.months", 2),
        "3 months": "3 " + this.$tc("dates.name.months", 3),
        "6 months": "6 " + this.$tc("dates.name.months", 6),
        "12 months": "12 " + this.$tc("dates.name.months", 12)
      }
    };
  },
  mounted() {
    this.data = this.getDefault();
    this.setSave(this.data);
  },
  methods: {
    setSave(ret) {
      this.data = ret;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.include.period = this.data.split(" ");
      data.include.period[0] = data.include.period[0] * 1;
      this.$store.commit("business/prefetch", data);
    },
    getDefault() {
      if (
        typeof this.$store.state.business.unsaved.include.period !== "object" ||
        !this.$store.state.business.unsaved.include.period.length
      ) {
        return this.defaultValue;
      }
      return this.$store.state.business.unsaved.include.period.join(" ");
    }
  }
};
</script>
<style lang="sass" scoped></style>
